import * as React from "react"
import { Link, navigate } from "gatsby"
import Seo from "../components/seo"
import { getScore } from "../utils/score"
import { useQuestionContext } from "../context/question"

const CompletedPage = ({ pageContext: { published } }) => {
  const [score, setScore] = React.useState()
  const { state } = useQuestionContext()

  React.useEffect(() => {
    if (!state || Object.keys(state).length === 0) return
    setScore(getScore(published))

    if (typeof window === "undefined") {
      return
    }

    if (isNaN(score)) return

    if (
      !Array.isArray(JSON.parse(localStorage.getItem(`score_${published}`)))
    ) {
      localStorage.removeItem(`score_${published}`)
    }

    if (!localStorage.getItem(`score_${published}`)) {
      localStorage.setItem(`score_${published}`, JSON.stringify([]))
    }

    localStorage.setItem(
      `score_${published}`,
      JSON.stringify(
        JSON.parse(localStorage.getItem(`score_${published}`)).concat(score)
      )
    )

    localStorage.removeItem(`responses_${published}`)
  }, [score, published, state])

  if (!published || !state || Object.keys(state).length === 0) {
    if (typeof window !== "undefined") {
      navigate("/")
    }
  }

  return (
    <>
      <Seo title="Completed" />
      <div className="flex flex-col items-center">
        <div className="text-2xl">Score:</div>
        <div className="w-full my-8 border-4 text-center border-green-200 border-radius p-8 text-4xl bg-green-100">
          {score}%
        </div>

        <div className="flex">
          <Link
            to={`/valencia/${published}/${state && Object.keys(state)[0]}`}
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
          >
            <svg
              className="fill-current w-6 h-6 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M13.5 2c-5.629 0-10.212 4.436-10.475 10h-3.025l4.537 5.917 4.463-5.917h-2.975c.26-3.902 3.508-7 7.475-7 4.136 0 7.5 3.364 7.5 7.5s-3.364 7.5-7.5 7.5c-2.381 0-4.502-1.119-5.876-2.854l-1.847 2.449c1.919 2.088 4.664 3.405 7.723 3.405 5.798 0 10.5-4.702 10.5-10.5s-4.702-10.5-10.5-10.5z" />
            </svg>
            <span>Start again</span>
          </Link>
          <Link
            to="/"
            className="ml-4 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
          >
            <svg
              className="fill-current w-6 h-6 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M21 13v10h-6v-6H9v6H3V13H0L12 1l12 12h-3zm-1-6V2h-3v2l3 3z" />
            </svg>
            <span>Tornar a l'inici</span>
          </Link>
        </div>
      </div>
    </>
  )
}

export default CompletedPage
